.cb-blog-content {
    display: grid;
    padding: 20px 0;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: auto;
    column-gap: 20px;
    row-gap: 20px;
}

.cb-blog-content .cb-blog-item {
    display: flex;
    flex-direction: column;
    background-color: #ffffff;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
}

.cb-blog-content .cb-blog-item.main {
    grid-column-start: 1;
    grid-column-end: 3;
    grid-row-start: 1;
    grid-row-end: 3;
}

.cb-blog-content .cb-blog-item .imagen {
    position: relative;
}

.cb-blog-content .cb-blog-item .imagen.nodisponible,
.cb-blog-seleccionado-contenido .ultimos .cb-ultimos-item .imagen.nodisponible,
.cb-blog-seleccionado-contenido .contenido .imagenes .imagen.nodisponible {
    max-width: 100%;
    display: flex;
    height: 250px;
    justify-content: center;
    align-items: center;
    background-color: #ececec;
    border-radius: 5px;
}

.cb-blog-seleccionado-contenido .ultimos .cb-ultimos-item .imagen.nodisponible {
    height: 100px;
    width: 170px;
}

.cb-blog-content .cb-blog-item .imagen img {
    max-width: 100%;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
}

.cb-blog-content .cb-blog-item .imagen .fecha {
    position: absolute;
    left: 0;
    bottom: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: #ffffff;
    background-color: #85b541;
    padding: 0 10px;
}

.cb-blog-content .cb-blog-item .imagen .fecha span {
    font-size: 13px;
}

.cb-blog-content .cb-blog-item .contenido {
    padding: 0px 20px 30px 20px;
}

.cb-blog-content .cb-blog-item .contenido h1,
.cb-blog-content .cb-blog-item .contenido h2 {
    color: #30517b;
}

.cb-blog-content .cb-blog-item .contenido span {
    font-weight: bolder;
}

.cb-blog-content .cb-blog-item .contenido .accion,
.cb-blog-seleccionado .cb-boton.accion {
    border-radius: 5px;
    background-color: #85b541;
    color: #30517b;
    margin-top: 5px;
    margin-left: 0px;
}

.cb-blog-content .cb-blog-item.loader .imagen,
.cb-blog-content .cb-blog-item.loader .contenido {
    background: #eee;
    background: linear-gradient(110deg, #ececec 8%, #f5f5f5 18%, #ececec 33%);
    border-radius: 5px;
    background-size: 200% 100%;
    animation: 1.5s shine linear infinite;
    
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
}

.cb-blog-content .cb-blog-item.loader .imagen {
    height: 250px;
}

.cb-blog-content .cb-blog-item.loader .contenido {
    height: 30px;
    margin-top: 10px;
    margin-bottom: 10px;
}

.cb-blog-seleccionado {
    margin-bottom: 30px;
}

.cb-blog-seleccionado > h1 {
    color: #30517b;
    border-bottom: 3px solid #d1efe8;
}

.cb-blog-seleccionado-contenido {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-areas: "item item last";
    column-gap: 20px;
    margin: 20px 0px;
}

.cb-blog-seleccionado-contenido .contenido {
    grid-area: item;
}

.cb-blog-seleccionado-contenido .contenido .imagenes img {
    max-width: 100%;
}

.cb-blog-seleccionado-contenido .contenido > span {
    color: #9c9e9e;
    padding: 20px 0;
    display: block;
}
.cb-blog-seleccionado-contenido .ultimos {
    grid-area: last;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}

.cb-blog-seleccionado-contenido .ultimos > h3 {
    color: #30517b;
    background-color: #85b541;
    padding: 20px;
    margin-top: 0px;
}

.cb-blog-seleccionado-contenido .ultimos .cb-ultimos-item {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 10px;
    padding-left: 20px;
    margin-bottom: 20px;
}

.cb-blog-seleccionado-contenido .ultimos .cb-ultimos-item > img {
    border-radius: 5px;
}

.cb-blog-seleccionado-contenido .ultimos .cb-ultimos-item > div {
    color: #30517b;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

@keyframes shine {
    to {
      background-position-x: -200%;
    }
  }

@media (max-width: 576px) {
    .cb-blog-content {
        grid-template-columns: 1fr;
    }

    .cb-blog-content .cb-blog-item.main {
        grid-column-start: 1;
        grid-column-end: 2;
        grid-row-start: 1;
        grid-row-end: 2;
    }

    .cb-blog-content .cb-blog-item .contenido {
        padding: 0px 0px 20px 0px;
    }

    .cb-blog-seleccionado-contenido {
        grid-template-columns: 1fr;
        grid-template-areas: "item" 
        "last";
        margin: 20px 0px;
    }
}