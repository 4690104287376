.cb-paginacion {
    position: relative;
    width: 100%;
    display: flex;
    justify-content: center;
    margin-bottom: 50px;
}

.cb-paginacion button {
    -webkit-appearance: none;
    background: transparent;
    border: 0;
    outline: 0;
    margin: 1px 20px;
    width: 25px;
    height: 25px;
    cursor: pointer;
    transform: translate3d(0, 0, 0);
    position: absolute;
    top: 50%;
    margin-top: -20px;
    -webkit-filter: drop-shadow(0 2px 0px rgba(0, 0, 0, 0.2));
}

.cb-paginacion .pagina i {
    position: absolute;
    top: 15px;
    left: 0;
    width: 20px;
    height: 5px;
    border-radius: 2.5px;
    background-color: #ffffff;
    transition: all 0.15s ease;
}

.cb-paginacion .pagina.left {
    right: 58%;
}

.cb-paginacion .pagina.left i {
    transform-origin: 0% 50%;
}

.cb-paginacion .pagina.left i:first-child {
    transform: translate(0, -1px) rotate(40deg);
}

.cb-paginacion .pagina.left i:last-child {
    transform: translate(0, 1px) rotate(-40deg);
}

.cb-paginacion .pagina.right {
    left: 58%;
}

.cb-paginacion .pagina.right i {
    transform-origin: 100% 50%;
}

.cb-paginacion .pagina.right i:first-child {
    transform: translate(0, 1px) rotate(40deg);
}

.cb-paginacion .pagina.right i:last-child {
    transform: translate(0, -1px) rotate(-40deg);
}

.cb-paginacion button.pagina:disabled i:first-child,
.cb-paginacion button.pagina:disabled i:last-child {
    transform: translate(-5px, 0) rotate(0deg);
}

.cb-paginacion button.pagina:disabled {
    cursor: not-allowed;
}