.cb-footer {
  position: relative;
  width: 100%;
  min-height: 100px;
  background: transparent linear-gradient(90deg, #013c6d 0%, #011e37 100%) 0% 0%
    no-repeat padding-box;
  font: normal normal 14px/21px Poppins-Medium;
  letter-spacing: 0px;
  color: #ffffff;
  bottom: -70px;
}

.cb-footer .cb-footer-contendor {
  display: grid;
  padding: 30px 100px 0px;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: auto;
  row-gap: 30px;
  grid-template-areas:
    'header . redes'
    'phone email address'
    'form form form'
    'legal legal legal';
}

.cb-footer .cb-footer-contendor .cb-logo {
  grid-area: header;
  width: 170px;
  height: 40px;
  -webkit-mask: url('../assets/public/logo.svg') no-repeat center;
  mask: url('../assets/public/logo.svg') no-repeat center;
  -webkit-mask-size: contain;
  mask-size: contain;
  background-color: #00df5c;
}

.cb-footer .cb-footer-contendor .cb-redes {
  grid-area: redes;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 5px;
}

.cb-footer .cb-footer-contendor .cb-phone {
  grid-area: phone;
  justify-self: center;
}
.cb-footer .cb-footer-contendor .cb-email {
  grid-area: email;
  justify-self: center;
}
.cb-footer .cb-footer-contendor .cb-address {
  grid-area: address;
  justify-self: center;
}

.cb-footer .cb-footer-contendor .cb-phone,
.cb-footer .cb-footer-contendor .cb-email,
.cb-footer .cb-footer-contendor .cb-address {
  display: grid;
  grid-template-columns: 35px auto;
  grid-template-rows: 1fr;
  column-gap: 10px;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-bottom-color: #ffffff;
  padding-bottom: 10px;
}

.cb-footer .cb-footer-contendor .cb-phone span,
.cb-footer .cb-footer-contendor .cb-email span,
.cb-footer .cb-footer-contendor .cb-address span {
  grid-row: 1 / 3;
  grid-column: 1;
  align-self: center;
}

.cb-footer .cb-footer-contendor .cb-phone div,
.cb-footer .cb-footer-contendor .cb-email div,
.cb-footer .cb-footer-contendor .cb-address div {
  grid-column: 2 / 3;
}

.cb-footer .cb-footer-contendor .cb-phone div:last-child {
  font-size: 18px;
}

.cb-footer .cb-footer-contendor span {
  border: 1px solid #ffffff;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.cb-footer .cb-footer-contendor span i {
  margin: 0;
}

.cb-footer .cb-footer-contendor .cb-form {
  grid-area: form;
  display: grid;
  grid-template-columns: 25% 25% 40% 10%;
  grid-template-rows: 1fr 1fr 1fr;
  row-gap: 5px;
  column-gap: 5px;
  width: calc(100% - 15px);
}

.cb-footer .cb-footer-contendor .cb-form input,
.cb-footer .cb-footer-contendor .cb-form textarea,
.cb-footer .cb-footer-contendor .cb-form select {
  padding: 15px 10px 15px 10px;
  font-size: 14px;
  border-radius: 11px;
  border: 1px solid #e1f2f5;
  background: #ffffff 0% 0% no-repeat padding-box;
}

.cb-footer .cb-footer-contendor .cb-form input:focus,
.cb-footer .cb-footer-contendor .cb-form textarea:focus {
  outline: none;
}

.cb-footer .cb-footer-contendor .cb-form input:nth-of-type(1) {
  grid-column: 1;
  grid-row: 1;
}

.cb-footer .cb-footer-contendor .cb-form input:nth-of-type(2) {
  grid-column: 2;
  grid-row: 1;
}

.cb-footer .cb-footer-contendor .cb-form input:nth-of-type(3) {
  grid-column: 1;
  grid-row: 2;
}

.cb-footer .cb-footer-contendor .cb-form textarea {
  grid-column: 3;
  grid-row: 1 / 3;
  resize: none;
  font: normal normal 14px/21px Poppins-Medium;
}

.cb-footer .cb-footer-contendor .cb-form select {
  grid-column: 2;
  grid-row: 2;
  appearance: none;
  background-image: url("data:image/svg+xml;utf8,<svg fill='black' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M4.516 7.548c0.436-0.446 1.043-0.481 1.576 0l3.908 3.747 3.908-3.747c0.533-0.481 1.141-0.446 1.574 0 0.436 0.445 0.408 1.197 0 1.615-0.406 0.418-4.695 4.502-4.695 4.502-0.217 0.223-0.502 0.335-0.787 0.335s-0.57-0.112-0.789-0.335c0 0-4.287-4.084-4.695-4.502s-0.436-1.17 0-1.615z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
  background-repeat: no-repeat;
  background-position-x: 100%;
  background-position-y: 13px;
}

.cb-footer .cb-footer-contendor .cb-form button {
  grid-column: 4;
  grid-row: 1 / 3;
  background-color: #00df5c;
  border-radius: 11px;
  border: none;
  cursor: pointer;
}

.cb-footer .cb-footer-contendor .cb-form div {
  grid-row: 3;
  grid-column: 1 / 5;
}

.cb-footer .cb-footer-contendor .cb-legal {
  grid-area: legal;
}

.cb-footer .cb-footer-contendor .cb-legal label {
  text-align: center;
  width: 100%;
  display: block;
  font-size: 12px;
}

.cb-footer .cb-footer-contendor .cb-legal div {
  width: 100%;
  border-bottom: 1px solid #ffffff;
  opacity: 0.5;
  margin-bottom: 10px;
}

.cb-footer .cb-footer-contendor .cb-legal p {
  text-align: justify;
  font-size: 0.8rem;
  letter-spacing: -0.09px;
  line-height: 1.3;
  margin: 0;
  opacity: 0.5;
}

@media (max-width: 576px) {
  .cb-footer .cb-footer-contendor {
    padding: 30px 30px 0;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto;
    row-gap: 20px;
    grid-template-areas:
      'header redes'
      'phone phone'
      'email email'
      'address address'
      'form form'
      'legal legal';
  }

  .cb-footer .cb-footer-contendor .cb-phone,
  .cb-footer .cb-footer-contendor .cb-email,
  .cb-footer .cb-footer-contendor .cb-address {
    width: 100%;
  }

  .cb-footer .cb-footer-contendor .cb-form {
    grid-template-columns: 1fr;
    grid-template-rows: auto;
    row-gap: 10px;
    width: 100%;
  }

  .cb-footer .cb-footer-contendor .cb-form input:nth-of-type(1) {
    grid-column: 1;
    grid-row: 1;
  }

  .cb-footer .cb-footer-contendor .cb-form input:nth-of-type(2) {
    grid-column: 1;
    grid-row: 2;
  }

  .cb-footer .cb-footer-contendor .cb-form input:nth-of-type(3) {
    grid-column: 1;
    grid-row: 3;
  }

  .cb-footer .cb-footer-contendor .cb-form select {
    grid-column: 1;
    grid-row: 4;
  }

  .cb-footer .cb-footer-contendor .cb-form textarea {
    grid-column: 1;
    grid-row: 5;
  }

  .cb-footer .cb-footer-contendor .cb-form button {
    grid-column: 1;
    grid-row: 6;
    height: 50px;
  }

  .cb-footer .cb-footer-contendor .cb-form div {
    grid-column: 1;
    grid-row: 7;
  }

  .cb-footer .cb-footer-contendor .cb-form input:nth-of-type(2) {
    grid-column: 1;
    grid-row: 2;
  }
}
