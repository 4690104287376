.cb-slider {
  position: relative;
  top: 1px;
  width: calc(100% + 200px);
  height: calc(100vh - 91px);
  overflow: hidden;
  left: -100px;
}

.cb-slider .cb-slides {
  position: absolute;
  width: 400%;
  height: 100%;
  top: 0;
  left: 0;
  transition: 0.3s ease;
}

.cb-slide-content {
  display: flex;
  height: 100%;
  width: 25%;
  position: relative;
  float: left;
}

.cb-slide-content > div {
  flex-basis: 50%;
}

.cb-slide-content .cb-data {
  background: transparent linear-gradient(90deg, #011e37 0%, #013c6d 100%) 0% 0%
    no-repeat padding-box;
}

.cb-slide-content .cb-data .cb-info {
  position: relative;
  height: 100%;
  left: 150px;
  width: calc(100% - 150px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.cb-slide-content .cb-data .cb-info .image {
  position: relative;
  width: 40px;
  height: 40px;
  background-image: url('../../assets/commons/forma.svg');
  background-repeat: no-repeat;
  background-size: 100%;
}

.cb-slide-content .cb-data .cb-info .title {
  font: normal normal bold 50px/55px Poppins-Bold;
  letter-spacing: 0px;
  color: #ffffff;
}

.cb-slide-content .cb-data .cb-info .title span {
  color: #17dd66;
}

.cb-slide-content .cb-data .cb-info .text {
  font: normal normal normal 16px/20px Poppins;
  letter-spacing: 0px;
  color: #ffffff;
  padding-right: 40px;
}

.cb-slide-content .cb-data .cb-info .actions {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.cb-slide-content .cb-image {
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
}

.cb-slider .cb-arrow {
  position: absolute;
  height: 40px;
  width: 40px;
  top: 50%;
  background-repeat: no-repeat;
  background-size: 100%;
  cursor: pointer;
}

.cb-slider .cb-arrow.prev {
  background-image: url('../../assets/home/prev.svg');
  left: 100px;
}

.cb-slider .cb-arrow.next {
  background-image: url('../../assets/home/next.svg');
  right: 100px;
}

.cb-slider .cb-dots {
  position: absolute;
  bottom: 1%;
  width: 100%;
  left: 20%;
}

.cb-slider .cb-dots span {
  width: 10px;
  height: 10px;
  border-radius: 100px;
  border: 3px solid #ffffff;
  background-color: transparent;
  display: inline-block;
  margin: 0 5px;
  transition: 0.4s linear;
  cursor: pointer;
}

.cb-slider .cb-dots span.dot-active {
  background-color: #ffffff;
}

@media (max-width: 576px) {
  .cb-slider {
    height: 1000px;
    width: calc(100% + 60px);
    left: -30px;
  }

  .cb-slide-content {
    flex-wrap: wrap;
  }

  .cb-slide-content > div {
    flex-basis: 100%;
    height: 500px;
  }

  .cb-slide-content .cb-data .cb-info {
    left: 30px;
    width: calc(100% - 30px);
  }

  .cb-slide-content .cb-data .cb-info .image {
    width: 25px;
    height: 25px;
  }

  .cb-slide-content .cb-data .cb-info .title {
    font: normal normal 25px/30px Poppins-Bold;
  }

  .cb-slide-content .cb-data .cb-info .text {
    font: normal normal normal 14px/20px Poppins;
  }

  .cb-slider .cb-arrow {
    top: 450px;
    height: 25px;
    width: 25px;
    left: 30px;
  }

  .cb-slider .cb-arrow.prev {
    left: 30px;
  }

  .cb-slider .cb-arrow.next {
    left: 75px;
  }

  .cb-slider .cb-dots {
    display: none;
  }
}
