.cb-global-perfil {
  display: flex;
  flex-direction: column;
  width: 250px;
  min-height: 400px;
}

.cb-global-perfil .avatar {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 250px;
  height: 250px;
}

.cb-global-perfil label {
  font: normal normal bold 18px/38px Poppins-Bold;
  letter-spacing: 0px;
  color: #0077ff;
}

.cb-global-perfil span,
.cb-global-perfil p {
  font: normal normal normal 15px/40px Poppins;
  letter-spacing: 0px;
  color: #7a839e !important;
}

.cb-global-perfil p {
  padding: 0 5px;
}

.cb-global-perfil .separador {
  height: 1px;
  width: 100%;
  background-color: #d1d1d1;
}

.cb-global-perfil .redes .facebook::before {
  content: '\f09a';
  font-family: 'fontAwesome';
  color: #ffffff;
  background-color: #1877f2;
  border-radius: 50%;
  height: 25px;
  width: 25px;
  display: block;
  text-align: center;
  line-height: 25px;
}

@media (max-width: 576px) {
  .cb-global-perfil {
    margin: 10px 0;
  }

  .cb-global-perfil label {
    font-size: 16px;
  }

  .cb-global-perfil span,
  .cb-global-perfil p {
    font-size: 13px;
  }
}
