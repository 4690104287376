.cb-nosotros-iconos {
  display: flex;
  justify-content: space-around;
}

.cb-nosotros-valores label {
  font-size: 40px;
  line-height: 22px;
  color: #42495b;
  font-weight: bold;
}

.cb-nosotros-valores p {
  font-size: 15px;
  line-height: 15px;
  color: #42495b;
}

.cb-nosotros-valores .valores {
  display: grid;
  margin: 50px 0;
  grid-template-columns: repeat(5, 1fr);
}

.cb-nosotros-valores .valores span {
  padding: 10px 20px;
  font: normal normal normal 18px/20px Poppins;
  letter-spacing: 0px;
  color: #7a839e;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 2px 5px #a7a7a766;
  margin: 10px 10px 10px 0;
  text-align: center;
  border-radius: 5px;
  border-bottom: 5px solid #ffaf03;
}

.cb-nosotros-valores .valores span:nth-of-type(2) {
  border-bottom-color: #ff7101;
}

.cb-nosotros-valores .valores span:nth-of-type(3) {
  border-bottom-color: #f13f3f;
}

.cb-nosotros-valores .valores span:nth-of-type(4) {
  border-bottom-color: #3c72ba;
}

.cb-nosotros-valores .valores span:nth-of-type(5) {
  border-bottom-color: #3e98d4;
}

.cb-nosotros-promo .imagen {
  filter: brightness(50%);
}

.cb-nosotros-promo .contenedor {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.cb-nosotros-promo .contenedor > label {
  font-size: 30px;
  line-height: 30px;
  color: #ffffff;
  margin-bottom: 20px;
}

.cb-nosotros-promo .contenedor > div {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 20px;
}

.cb-nosotros-perfil .content .title {
  color: #42495b !important;
}

.cb-nosotros-perfil .perfiles {
  display: flex;
  justify-content: space-around;
}
.cb-nosotros-mision {
  margin-top: 30px;
  text-align: center;
}

.cb-nosotros-mision.somos {
  margin-top: 0;
  padding-top: 100px;
}

.cb-nosotros-mision .content > label {
  font-size: 40px;
  color: #42495b;
  font-weight: bold;
}

.cb-nosotros-mision .content .texto {
  margin-top: 50px;
  text-align: left;
}

.cb-nosotros-vision {
  margin-top: 30px;
  text-align: center;
}
.cb-nosotros-vision .content > label {
  font-size: 40px;
  color: #42495b;
  font-weight: bold;
}

.cb-nosotros-vision .content .texto {
  text-align: left;
  margin-top: 50px;
  letter-spacing: 0px;
  color: #42495b;
}

.cb-nosotros-vision .imagen-border {
  border-radius: 10px;
}

@media (max-width: 576px) {
  .cb-nosotros-valores .valores {
    grid-template-columns: repeat(1, 1fr);
  }

  .cb-nosotros-valores .valores span {
    font: normal normal normal 15px/20px Poppins;
  }

  .cb-nosotros-valores .content > label,
  .cb-nosotros-mision .content > label,
  .cb-nosotros-vision .content > label,
  .cb-nosotros-promo .contenedor > label {
    font: normal normal 800 22px/56px Poppins-ExtraBold;
  }

  .cb-nosotros-valores .content > .texto,
  .cb-nosotros-mision .content > .texto,
  .cb-nosotros-vision .content > .texto {
    font: normal normal normal 14px/18px Poppins;
  }

  .cb-nosotros-mision.somos {
    padding-top: 0;
  }

  .cb-nosotros-promo .contenedor > div {
    grid-template-columns: repeat(1, 1fr);
  }

  .cb-nosotros-perfil .perfiles {
    flex-direction: column;
    align-items: center;
  }
}
