.cb-inicio-five .content {
  position: relative;
  width: 100%;
  flex-direction: row !important;
  padding: 0 !important;
}

.cb-inicio-five .content .bloque {
  flex-basis: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.cb-inicio-five .content .bloque:first-child {
  padding-right: 10px;
}

.cb-inicio-five .content .bloque:last-child {
  padding-left: 10px;
}

.cb-inicio-five .content .bloque .image {
  position: relative;
  width: 30px;
  height: 30px;
  -webkit-mask: url('../assets/commons/forma.svg') no-repeat center;
  mask: url('../assets/commons/forma.svg') no-repeat center;
  -webkit-mask-size: contain;
  mask-size: contain;
  background-color: #17dd66;
}

.cb-inicio-five .content .bloque .title {
  font: normal normal 30px/22px Poppins-Bold;
  letter-spacing: 0px;
  color: #013c6d;
  margin: 15px 0;
}

.cb-inicio-five .content .bloque ul.datos {
  list-style: none;
  font: normal normal medium 16px/25px Poppins;
  letter-spacing: 0px;
  color: #42495b;
  padding: 0;
}

.cb-inicio-five .content .bloque ul.datos li {
  display: flex;
  align-items: flex-end;
}

.cb-inicio-five .content .bloque ul.datos li::before {
  content: '';
  background-repeat: no-repeat;
  background-size: 100%;
  text-decoration: none;
  width: 19px;
  height: 19px;
  display: inline-block;
  margin-right: 5px;
}

.cb-inicio-five .content .bloque ul.datos li:nth-child(1)::before {
  background-image: url('../assets/contacto/mail.svg');
}

.cb-inicio-five .content .bloque ul.datos li:nth-child(2)::before {
  background-image: url('../assets/contacto/phone.svg');
}

.cb-inicio-five .content .bloque ul.datos li:nth-child(3)::before {
  background-image: url('../assets/contacto/map-pin.svg');
  background-size: 85%;
}

.cb-inicio-five .content .bloque .checkbox {
  display: flex;
  justify-content: flex-start;
}

.cb-inicio-five .content .bloque .checkbox input[type='checkbox'] {
  width: 20px;
}

.cb-inicio-five .content .bloque .checkbox label {
  color: #42495b;
  left: 24px;
  top: 0;
  font: normal normal normal 16px/20px Poppins;
  letter-spacing: 0px;
}

@media (max-width: 576px) {
  .cb-inicio-five .content {
    flex-direction: column !important;
  }
  .cb-inicio-five .content .bloque {
    flex-basis: 100%;
  }
}
