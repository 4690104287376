.op-form-group .select-op-control {
  display: flex;
  justify-content: space-between;
  box-shadow: none;
  border: 1px solid #cccccc;
  padding: 0;
  font-family: 'Arial';
  font-size: 14px;
  width: available;
  width: -moz-available;
  width: -webkit-fill-available;
  min-height: 48px;
}

.op-form-group .select-op-control ~ label {
  top: -10px;
  background-color: #ffffff;
  padding: 0 2px;
  left: 8px;
}

.op-form-group .select-op-control ~ label.on,
.op-form-group .select-op-control.valid:not(.disabled) ~ label {
  color: #3498db;
  font-weight: bold;
  font-size: 16px;
}

.op-form-group label.on ~ span.bar.bottom::before,
.op-form-group label.on ~ span.bar.top::before,
.op-form-group
  .select-op-control.valid:not(.disabled)
  ~ span.bar.bottom::before,
.op-form-group .select-op-control.valid:not(.disabled) ~ span.bar.top::before {
  width: 100%;
}

.op-form-group label.on ~ span.bar.left::before,
.op-form-group label.on ~ span.bar.right::before,
.op-form-group .select-op-control.valid:not(.disabled) ~ span.bar.left::before,
.op-form-group
  .select-op-control.valid:not(.disabled)
  ~ span.bar.right::before {
  height: inherit;
}

.op-form-group.error .select-op-control ~ label {
  color: red !important;
}

.op-form-group .select-op-control .select-op-container {
  padding: 15px 10px 15px 10px;
  position: relative;
  width: available;
  width: -moz-available;
  width: -webkit-fill-available;
}

.op-form-group .select-op-control .select-op-container .select-op-label {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  padding-left: 10px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.op-form-group .select-op-control .select-op-container.multiple {
  display: flex;
  flex-wrap: wrap;
  padding: 10px;
}

.op-form-group .select-op-control .select-op-container.multiple .op-multiple {
  background-color: rgb(230, 230, 230);
  border-radius: 2px;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  margin-right: 2px;
  margin-bottom: 2px;
  z-index: 2;
}

.op-form-group
  .select-op-control
  .select-op-container.multiple
  .op-multiple
  .op-label-multiple {
  background-color: rgb(230, 230, 230);
  border-radius: 2px;
  overflow: hidden;
  padding: 3px 3px 3px 6px;
  text-overflow: ellipsis;
  white-space: nowrap;
  box-sizing: border-box;
}

.op-form-group
  .select-op-control
  .select-op-container.multiple
  .op-multiple
  .op-label-remove {
  -moz-box-align: center;
  align-items: center;
  border-radius: 2px;
  display: flex;
  box-sizing: border-box;
  padding: 0 4px;
  cursor: pointer;
}

.op-form-group
  .select-op-control
  .select-op-container.multiple
  .op-multiple
  .op-label-remove:hover {
  background-color: rgb(255, 189, 173);
  color: red;
}

.op-form-group .select-op-control .select-op-container .select-op-search {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  width: -moz-available;
  width: -webkit-fill-available;
  visibility: hidden;
}

.op-form-group .select-op-control .select-op-container.on .select-op-search {
  visibility: visible;
}

.op-form-group
  .select-op-control
  .select-op-container.multiple.on
  .select-op-search {
  visibility: visible;
  position: relative;
  width: 100%;
}

.op-form-group .select-op-control .select-op-container .select-op-search input {
  border: none;
  padding: 15px 10px 15px 10px;
}

.op-form-group
  .select-op-control
  .select-op-container.multiple
  .select-op-search
  input {
  padding: 3px 0;
  width: 100%;
}

.op-form-group .select-op-control .select-op-actions {
  display: flex;
  justify-content: space-between;
}

.op-form-group .select-op-control .select-op-actions .select-op-separador {
  width: 1px;
  height: 38px;
  margin: 5px 0;
  background-color: #ffffff;
}

.op-form-group .select-op-control .select-op-actions .select-op-action {
  background: transparent;
  background-repeat: no-repeat;
  background-position-x: 100%;
  background-position-y: center;
  width: 24px;
}

.op-form-group
  .select-op-control
  .select-op-actions
  .select-op-action.dropdown {
  background-image: url("data:image/svg+xml;utf8,<svg fill='black' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M4.516 7.548c0.436-0.446 1.043-0.481 1.576 0l3.908 3.747 3.908-3.747c0.533-0.481 1.141-0.446 1.574 0 0.436 0.445 0.408 1.197 0 1.615-0.406 0.418-4.695 4.502-4.695 4.502-0.217 0.223-0.502 0.335-0.787 0.335s-0.57-0.112-0.789-0.335c0 0-4.287-4.084-4.695-4.502s-0.436-1.17 0-1.615z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
}

.op-form-group .select-op-control .select-op-actions .select-op-action.close {
  cursor: pointer;
  background-image: url("data:image/svg+xml;utf8,<svg fill='black' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M14.348 14.849c-0.469 0.469-1.229 0.469-1.697 0l-2.651-3.030-2.651 3.029c-0.469 0.469-1.229 0.469-1.697 0-0.469-0.469-0.469-1.229 0-1.697l2.758-3.15-2.759-3.152c-0.469-0.469-0.469-1.228 0-1.697s1.228-0.469 1.697 0l2.652 3.031 2.651-3.031c0.469-0.469 1.228-0.469 1.697 0s0.469 1.229 0 1.697l-2.758 3.152 2.758 3.15c0.469 0.469 0.469 1.229 0 1.698z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
}

.op-form-group .select-op-list {
  position: absolute;
  bottom: -50px;
  width: available;
  width: -moz-available;
  width: -webkit-fill-available;
  max-height: 300px;
  display: flex;
  flex-direction: column;
  border: 1px solid #cccccc;
  border-radius: 5px;
  background-color: #ffffff;
  overflow: auto;
  z-index: 3;
}

.op-form-group .select-op-list.top {
  bottom: auto;
}

.op-form-group .select-op-list span {
  padding: 5px 10px;
  font-size: 14px;
  font-family: 'Arial';
}

.op-form-group .select-op-list span.selected {
  background-color: #3498db;
  color: #ffffff;
}

.op-form-group .select-op-list.multiple span.selected {
  display: none;
}

.op-form-group .select-op-list span:not(.selected):not(.empty):hover {
  background-color: #3498db2b;
}

.op-form-group .select-op-list span.empty {
  text-align: center;
}

.op-form-group .select-op-control.disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}

.op-form-group .select-op-control.disabled ~ label {
  color: #999999;
  background-color: transparent;
}

.op-form-group
  .select-op-control.disabled
  .select-op-container
  .select-op-label {
  color: #999999;
}

.op-form-group
  .select-op-control.disabled
  .select-op-actions
  .select-op-separador {
  background-color: #cccccc;
}

@media (max-width: 767px) {
  .op-form-group .select-op-list {
    background-color: #e4ebf8;
  }
}
