.cb-global-titulo {
  width: 100%;
  height: 200px;
  display: flex;
  justify-content: start;
  align-items: center;
}

.cb-global-titulo .imagen {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  height: 200px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  filter: brightness(60%);
  z-index: -1;
}

.cb-global-titulo span {
  color: #ffffff;
  font: normal normal 800 35px/56px Poppins-ExtraBold;
  letter-spacing: 0px;
  z-index: 2;
}

.cb-global-titulo .overlay {
  background: transparent linear-gradient(90deg, #013c6d 0%, #011e37 100%) 0% 0%
    no-repeat padding-box;
  opacity: 0.7;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 200px;
}

@media (max-width: 576px) {
  .cb-global-titulo,
  .cb-global-titulo .overlay {
    height: 150px;
  }
  .cb-global-titulo span {
    font-size: 25px;
  }

  .cb-global-titulo .imagen {
    height: 150px;
  }
}
