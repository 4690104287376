.cb-global-icono {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-template-rows: 40px auto;
  width: 175px;
  height: 120px;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 5px 10px #a7a7a770;
  border-radius: 5px;
  padding: 15px 10px 10px 10px;
  position: relative;
}

.cb-global-icono .content-icon {
  position: absolute;
  top: -35px;
  left: 65px;
  background-color: #ffffff;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.cb-global-icono .icono {
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  width: 30px;
  height: 30px;
}

.cb-global-icono span.title-icon {
  text-align: center;
  font: normal normal 800 25px/56px Poppins-ExtraBold;
  letter-spacing: 0px;
  color: #42495b;
  opacity: 0.3;
}

.cb-global-icono span.texto-icon {
  font: normal normal normal 16px/18px Poppins;
  letter-spacing: 0px;
  color: #7a839e;
  text-align: center;
}

@media (max-width: 576px) {
  .cb-global-icono {
    width: 100%;
    margin-bottom: 45px;
    padding-top: 30px;
  }

  .cb-global-icono .content-icon {
    left: 40%;
    height: 80px;
    width: 80px;
    top: -40px;
  }

  .cb-global-icono .icono {
    height: 40px;
    width: 40px;
  }

  .cb-global-icono span.texto-icon {
    font-size: 15px;
  }
}
