.cb-inicio-two .content {
  flex-direction: row !important;
  justify-content: space-around;
  margin-bottom: 100px;
}

.cb-inicio-two .cb-aliado {
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  border-radius: 5px;
  height: 92px;
  width: 190px;
}

.cb-inicio-three .content {
  position: relative;
  height: 378px;
  width: 100%;
  flex-direction: row !important;
  padding: 0 !important;
}

.cb-inicio-three .content .bloque {
  position: relative;
  flex-basis: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 50px;
}

.cb-inicio-three .content .bloque .data {
  flex-basis: calc(100% - 163px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}
.cb-inicio-three .content .bloque .image {
  flex-basis: 163px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.cb-inicio-three .content .bloque .data .icono,
.cb-inicio-three .content .bloque .data .icono-transparent {
  position: relative;
  width: 30px;
  height: 30px;
  -webkit-mask: url('../assets/commons/forma.svg') no-repeat center;
  mask: url('../assets/commons/forma.svg') no-repeat center;
  -webkit-mask-size: contain;
  mask-size: contain;
  background-color: #ffffff;
}

.cb-inicio-three .content .bloque .data .icono-transparent {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 65px;
  height: 65px;
  transform: rotateZ(270deg);
  opacity: 0.2;
}

.cb-inicio-three .content .bloque .data .title {
  font: normal normal 30px/35px Poppins-Bold;
  letter-spacing: 0px;
  color: #ffffff;
  margin-bottom: 15px;
}

.cb-inicio-three .content .bloque.first {
  background: transparent linear-gradient(270deg, #013c6d 0%, #011e37 100%) 0%
    0% no-repeat padding-box;
  border-radius: 20px 0px 0px 20px;
}

.cb-inicio-three .content .bloque.second {
  background: transparent linear-gradient(270deg, #17dd66 0%, #059f42 100%) 0%
    0% no-repeat padding-box;
  border-radius: 0px 20px 20px 0px;
}

.cb-inicio-three .content .bloque.second .data,
.cb-inicio-three .content .bloque.fourth .data {
  align-items: flex-end;
}

.cb-inicio-three .content .bloque.second .data .icono,
.cb-inicio-three .content .bloque.fourth .data .icono {
  transform: rotateZ(270deg);
}

.cb-inicio-three .content .bloque.third .data .icono,
.cb-inicio-three .content .bloque.fourth .data .icono {
  background-color: #17dd66;
}

.cb-inicio-three .content .bloque.second .data .title,
.cb-inicio-three .content .bloque.fourth .data .title {
  text-align: right;
}

.cb-inicio-three .content .bloque.third .data .title,
.cb-inicio-three .content .bloque.fourth .data .title {
  font: normal normal 20px/25px Poppins-Medium;
  letter-spacing: 0px;
  color: #013c6d;
}

.cb-inicio-four .content > .image {
  position: relative;
  width: 30px;
  height: 30px;
  -webkit-mask: url('../assets/commons/forma.svg') no-repeat center;
  mask: url('../assets/commons/forma.svg') no-repeat center;
  -webkit-mask-size: contain;
  mask-size: contain;
  background-color: #17dd66;
}

.cb-inicio-four .content > .title {
  font: normal normal bold 30px/22px Poppins-Bold;
  letter-spacing: 0px;
  color: #013c6d;
}

.cb-inicio-four .content .texto {
  font: normal normal normal 16px/20px Poppins;
  letter-spacing: 0px;
  color: #42495b;
}

.cb-inicio-four .content .contenedor-imagenes {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 30px;
}

@media (max-width: 576px) {
  .cb-inicio-four .content > .image {
    width: 25px;
    height: 25px;
  }

  .cb-inicio-four .content > .title {
    font: normal normal bold 25px/22px Poppins-Bold;
  }

  .cb-inicio-four .content .texto {
    font: normal normal normal 14px/20px Poppins;
  }

  .cb-inicio-three .content {
    height: 500px;
    flex-direction: column !important;
  }

  .cb-inicio-three .content .bloque {
    height: 245px;
    padding: 0 30px;
    border-radius: 20px !important;
  }

  .cb-inicio-three .content .bloque.first {
    margin-bottom: 10px;
  }

  .cb-inicio-three .content .bloque.second .data,
  .cb-inicio-three .content .bloque.fourth .data {
    align-items: flex-start;
  }

  .cb-inicio-three .content .bloque .data {
    flex-basis: 100%;
  }

  .cb-inicio-three .content .bloque.second .data .icono,
  .cb-inicio-three .content .bloque.fourth .data .icono {
    transform: rotateZ(0deg);
  }

  .cb-inicio-three .content .bloque .data .title {
    font: normal normal 20px/25px Poppins-Bold;
  }

  .cb-inicio-three .content .bloque.second .data .title,
  .cb-inicio-three .content .bloque.fourth .data .title {
    text-align: left;
  }

  .cb-inicio-three .content .bloque .image {
    display: none;
  }

  .cb-inicio-two .content {
    flex-direction: column !important;
    justify-content: center;
    align-items: center;
    gap: 10px;
  }
}
