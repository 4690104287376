.cb-global-tipo {
	display: flex;
	flex-direction: column;
	flex: 1 1;
	padding: 10px 14px;
	background: #ffffff 0% 0% no-repeat padding-box;
	box-shadow: 0px 5px 10px #a7a7a76f;
	border-radius: 5px;
	margin: 0 10px;
}

.cb-global-tipo .title-tp {
	font-size: 30px;
	line-height: 30px;
	color: #42495b;
	font-weight: bold;
}

.cb-global-tipo .contenido {
	display: flex;
	flex-wrap: wrap;
	padding: 20px 0;
}

.cb-global-tipo .contenido .grupo {
	display: flex;
	justify-content: flex-start;
	align-items: center;
	flex: 0 1 50%;
	margin-bottom: 10px;
}

.cb-global-tipo .contenido .grupo span {
	color: #42495b;
	font-size: 15px;
	line-height: 15px;
	flex: 1 2;
}

.cb-global-tipo .subtitle-tp {
	font-size: 20px;
	line-height: 20px;
	color: #42495b;
	font-weight: bold;
}

.cb-global-tipo .datos thead tr:first-child td:last-child div {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.cb-global-tipo .datos thead tr td div select {
	-moz-appearance: none;
	-webkit-appearance: none;
	appearance: none;
	background: transparent;
	background-image: url("data:image/svg+xml;utf8,<svg fill='black' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
	background-repeat: no-repeat;
	background-position-x: 100%;
	background-position-y: 8px;
	border: none;
	padding: 10px 20px 10px 10px;
}

.cb-global-tipo .datos thead tr td div select:focus {
	outline: 0;
}

.cb-global-tipo .datos table {
	border: 1px solid #ededed;
	border-radius: 15px;
	padding: 10px 5px;
	border-collapse: collapse;
	margin-top: 10px;
	width: 100%;
}

.cb-global-tipo .datos table tr td {
	border: 1px solid #ededed;
	padding: 7px;
	border-collapse: collapse;
	font-size: 14px;
	line-height: 14px;
	text-align: center;
}

.cb-global-tipo .datos table tr td label::before {
	content: attr(data-perfil);
	display: block;
	height: 31px;
	width: 31px;
	background: transparent linear-gradient(90deg, #00b2bc 0%, #00fc7e 100%) 0% 0%
		no-repeat padding-box;
	color: #ffffff;
	border-radius: 50%;
	text-align: center;
	line-height: 25px;
}

.cb-global-tipo .datos table tr td label.A::before {
	background: #31db72 0% 0% no-repeat padding-box;
}

.cb-global-tipo .datos table tr td label.B::before {
	background: #ffc107 0% 0% no-repeat padding-box;
}

.cb-global-tipo .datos table tr td label.C::before {
	background: #fd7e14 0% 0% no-repeat padding-box;
}

.cb-global-tipo .datos table tr td label.D::before {
	background: #007bff 0% 0% no-repeat padding-box;
}

.cb-global-tipo .datos table tr td label.E::before {
	background: #6610f2 0% 0% no-repeat padding-box;
}

.cb-global-tipo .datos table tr td label.F::before {
	background: #6f42c1 0% 0% no-repeat padding-box;
}

@media (max-width: 576px) {
	.cb-global-tipo {
		flex: 0 1 100%;
		margin: 10px 0;
	}

	.cb-global-tipo .contenido .grupo {
		flex: 0 1 100%;
	}
}
