@font-face {
  font-family: Poppins;
  src: url('../assets/font/poppins/Poppins-Regular.otf');
}

@font-face {
  font-family: Poppins-ExtraBold;
  src: url('../assets/font/poppins/Poppins-ExtraBold.otf');
}

@font-face {
  font-family: Poppins-SemiBold;
  src: url('../assets/font/poppins/Poppins-SemiBold.otf');
}

@font-face {
  font-family: Poppins-Bold;
  src: url('../assets/font/poppins/Poppins-Bold.otf');
}

@font-face {
  font-family: Poppins-Medium;
  src: url('../assets/font/poppins/Poppins-Medium.otf');
}

body {
  padding: 0;
  margin: 0;
  font-family: Poppins;
}

.cb-body {
  position: relative;
  top: 90px;
  width: 100%;
  min-height: calc(100vh - 172px);
  overflow: hidden;
}

.cb-body .cb-body-contenedor {
  padding: 0 100px;
}

.cb-error {
  color: red;
}

.fa {
  margin-right: 5px;
}

@media (max-width: 576px) {
  .cb-body {
    top: 70px;
  }

  .cb-body .cb-body-contenedor {
    padding: 0 30px;
  }
}
