.cb-global-icono-text {
  display: grid;
  width: auto;
  min-height: 115px;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 5px;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 5px 10px #a7a7a770;
  border-radius: 5px;
  padding: 10px;
  padding-right: 20px;
}

.cb-global-icono-text .icono {
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  width: 50px;
  height: 110px;
  grid-column: 1;
  grid-row: 1;
  place-self: center;
}

.cb-global-icono-text .texto {
  align-self: center;
  grid-row: 1;
  grid-column: 2/4;
}

.cb-global-icono-text span:nth-of-type(1) {
  font: normal normal 600 18px/20px Poppins-SemiBold;
  letter-spacing: 0px;
  color: #7a839e;
  display: block;
}
.cb-global-icono-text span:nth-of-type(2) {
  margin-top: 10px;
  font: normal normal normal 15px/22px Poppins;
  letter-spacing: 0px;
  color: #7a839e;
  display: block;
}

@media (max-width: 576px) {
  .cb-global-icono-text {
    margin-bottom: 10px;
  }

  .cb-global-icono-text span:nth-of-type(1) {
    font: normal normal 600 14px/20px Poppins-SemiBold;
  }

  .cb-global-icono-text span:nth-of-type(2) {
    font: normal normal normal 12px/22px Poppins;
  }
}
