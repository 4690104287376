.cb-boton {
  border: 2px solid #ffffff;
  border-radius: 30px;
  font: normal normal 16px/17px Poppins-Medium;
  letter-spacing: 0px;
  color: #ffffff;
  padding: 10px 40px;
  background-color: transparent;
  cursor: pointer;
  margin: 0 5px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  position: relative;
}

.cb-boton.filled {
  background: #0062ff 0% 0% no-repeat padding-box;
  border: none;
  padding: 12px 40px;
}

.cb-boton.cb-dinamic {
  color: var(--button-color);
  border-color: var(--button-border-color);
}

.cb-boton:disabled,
.cb-boton:disabled:hover,
.cb-boton:disabled:focus {
  border-color: #999999;
  background-color: #cccccc;
  color: #666666;
  cursor: not-allowed;
}

.cb-boton .cb-loading-mini {
  background-color: #ffffff;
  -webkit-mask: url('../../assets/commons/loader-mini.svg') no-repeat center;
  mask: url('../../assets/commons/loader-mini.svg') no-repeat center;
  width: 25px;
  height: 100%;
  position: absolute;
  left: calc(50% - 12px);
  top: 2px;
}
