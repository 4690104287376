.cb-content-login {
  display: flex;
  width: 100%;
  min-height: 100vh;
}

.cb-content-login .cb-bloque {
  flex-basis: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.cb-content-login .cb-bloque.cb-fondo,
.cb-content-login .cb-bloque.cb-fondo-alterno {
  background-image: url('../../assets/login/2-imagen.png');
  background-repeat: no-repeat;
  background-size: cover;
}

.cb-content-login .cb-bloque.cb-fondo-alterno {
  background-image: url('../../assets/login/1-imagen.png');
}

.cb-content-login .cb-bloque.cb-fondo label,
.cb-content-login .cb-bloque.cb-fondo-alterno label {
  align-self: flex-end;
  font: normal normal medium 14px/21px Poppins-Medium;
  letter-spacing: 0px;
  color: #ffffff;
}

.cb-content-login .cb-bloque.cb-login {
  overflow: auto;
}

.cb-content-login .cb-bloque.cb-login .content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 70%;
  position: relative;
  top: 0;
}

.cb-content-login .cb-bloque.cb-login .content .logo {
  line-height: 90px;
  background-image: url('../../assets/public/logo.svg');
  background-repeat: no-repeat;
  background-size: 100%;
  text-decoration: none;
  width: 173px;
  height: 40px;
  display: block;
}

.cb-content-login .cb-bloque.cb-login .content .icono {
  position: relative;
  width: 30px;
  height: 30px;
  -webkit-mask: url('../..//assets/commons/forma.svg') no-repeat center;
  mask: url('../../assets/commons/forma.svg') no-repeat center;
  -webkit-mask-size: contain;
  mask-size: contain;
  background-color: #17dd66;
}

.cb-content-login .cb-bloque.cb-login .content .title {
  font: normal normal bold 30px/22px Poppins-Bold;
  letter-spacing: 0px;
  color: #013c6d;
  padding: 10px 0;
}
.cb-content-login .cb-bloque.cb-login .content .sub-title {
  font: normal normal normal 16px/20px Poppins;
  letter-spacing: 0px;
  color: #42495b;
  padding: 10px 0;
}

.cb-content-login .cb-bloque.cb-login .content .texto {
  font: normal normal normal 15px/22px Poppins;
  letter-spacing: 0px;
  color: #0077ff;
  padding: 10px 0;
  text-decoration: none;
  align-self: flex-start;
  display: flex;
  justify-content: center;
  align-items: center;
}

.cb-content-login .cb-bloque.cb-login .content .texto a {
  text-decoration: none;
  color: #0077ff;
}

.cb-content-login .cb-bloque.cb-login .content .checkbox {
  display: flex;
  justify-content: flex-start;
  margin-bottom: 25px;
}

.cb-content-login
  .cb-bloque.cb-login
  .content
  .checkbox
  input[type='checkbox'] {
  width: 20px;
}

.cb-content-login .cb-bloque.cb-login .content .checkbox label {
  font: normal normal normal 15px/22px Poppins;
  letter-spacing: 0px;
  color: #0077ff;
  left: 24px;
  top: 0;
}

.cb-content-login .cb-bloque.cb-login .content .cb-captcha {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.cb-content-login .cb-bloque.cb-login .content .cb-captcha > div {
  width: 100%;
  height: 50px;
  background-color: #cccccc;
}
