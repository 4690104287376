.cb-global-bloque {
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  position: relative;
  margin-top: 100px;
  flex-wrap: wrap;
}

.cb-global-bloque .titulo {
  flex-basis: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.cb-global-bloque .overlay {
  position: absolute;
  left: -100px;
  top: 0;
  right: -100px;
  width: auto;
  z-index: -1;
  height: 100%;
  background-color: #ffffff;
}

.cb-global-bloque .content {
  padding: 20px 50px 20px 0px;
  display: flex;
  flex-direction: column;
  flex-basis: calc(50% - 50px);
}

.cb-global-bloque .content.total {
  flex-basis: 100%;
}

.cb-global-bloque.alterno .content {
  padding: 20px 0px 20px 20px;
  order: 2;
}

.cb-global-bloque.fondo .content {
  padding: 20px 0;
}

.cb-global-bloque .titulo .subtitle {
  font: normal normal bold 30px/45px Poppins-Bold;
  letter-spacing: 0px;
  color: #013c6d;
}

.cb-global-bloque .titulo .title {
  color: #42495b;
  font: normal normal 800 55px/56px Poppins-ExtraBold;
  letter-spacing: 0px;
}

.cb-global-bloque .titulo .tema {
  color: #42495b;
  font-size: 25px;
  font-style: oblique;
  letter-spacing: 0px;
}

.cb-global-bloque .content p {
  color: #42495b;
  font-size: 20px;
  line-height: 20px;
  text-align: justify;
}

.cb-global-bloque.fondo .titulo .title,
.cb-global-bloque.fondo .content p {
  color: #ffffff;
}

.cb-global-bloque .imagen {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 5px;
  height: 300px;
  width: 100%;
  flex-basis: 50%;
  margin-bottom: 30px;
}

.cb-global-bloque.fondo .imagen {
  position: absolute;
  left: -100px;
  top: 0;
  right: -100px;
  width: auto;
  z-index: -1;
  height: 100%;
  margin-bottom: 0;
}

@media (max-width: 576px) {
  .cb-global-bloque {
    margin-top: 20px;
  }
  .cb-global-bloque .content {
    padding: 20px 0;
    order: 1;
    flex-basis: 100%;
    padding: 20px 0px;
  }

  .cb-global-bloque.alterno .content {
    padding: 20px 0px;
  }

  .cb-global-bloque .titulo .subtitle {
    font: normal normal bold 25px/30px Poppins-Bold;
  }

  .cb-global-bloque .titulo .title {
    font-size: 22px;
  }

  .cb-global-bloque .titulo .tema {
    font-size: 15px;
  }

  .cb-global-bloque .content p {
    font-size: 12px;
  }

  .cb-global-bloque .imagen {
    order: 2;
    flex-basis: 100%;
    background-size: contain;
  }

  .cb-global-bloque.fondo .imagen {
    background-size: cover;
  }
}
