.cb-carrusel {
    position: relative;
    max-width: 100%;
}

.cb-carrusel .cb-carrusel__navigation {
    position: absolute;
}

.cb-carrusel .cb-carrusel__navigation.left {
    left: 20px;
    top: 0;
    height: 100%;
}
.cb-carrusel .cb-carrusel__navigation.top {
    top: 20px;
    width: 100%;
}
.cb-carrusel .cb-carrusel__navigation.right {
    right: 20px;
    height: 100%;
}
.cb-carrusel .cb-carrusel__navigation.bottom {
    bottom: 20px;
    width: 100%;
}

.cb-carrusel .cb-arrow {
    position: absolute;
    height: 40px;
    width: 40px;
    top: 50%;
    background-repeat: no-repeat;
    background-size: 100%;
    background-color: #30517b;
    border: none;
    cursor: pointer;
}

.cb-carrusel .cb-arrow:disabled {
    cursor: not-allowed;
    background-color: #9e9e9e;
}

.cb-carrusel .cb-arrow.prev {
    mask: url('../../assets/home/prev.svg') no-repeat center;
    -webkit-mask: url('../../assets/home/prev.svg') no-repeat center;
    left: 20px;
    
}
  
.cb-carrusel .cb-arrow.next {
    mask: url('../../assets/home/next.svg') no-repeat center;
    -webkit-mask: url('../../assets/home/next.svg') no-repeat center;
    right: 20px;
}

.cb-carrusel .cb-carrusel__navigation .cb-carrusel__dots {
    display: flex;
    justify-content: center;
    gap: 5px
}

.cb-carrusel .cb-carrusel__navigation.left .cb-carrusel__dots,
.cb-carrusel .cb-carrusel__navigation.right .cb-carrusel__dots {
    flex-direction: column;
    height: 100%;
}

.cb-carrusel .cb-carrusel__navigation .cb-carrusel__dots span {
    width: 10px;
    height: 10px;
    border-radius: 100px;
    border: 3px solid #30517b;
    background-color: transparent;
    display: inline-block;
    margin: 0 5px;
    transition: 0.4s linear;
    cursor: pointer;
}

.cb-carrusel .cb-carrusel__navigation .cb-carrusel__dots span.active {
    background-color: #85b541;
}